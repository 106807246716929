import useGA from "../../hooks/useGA";
import { ReactComponent as MuzifyLogo } from "../../assets/images/logo-horizontal.svg";
import { SOCIALS, PAGES, GA_EVENTS } from "../../constants/dataConstant"

const Footer = () => {

    const { recordGAEvent } = useGA();
    
    const handlePagesClick = (event: React.MouseEvent<htmlbuttonelement, MouseEvent=""> , pageName: string, pageLink: string) => {
        event.preventDefault();
        if (pageName === "privacypolicy") {
            recordGAEvent(GA_EVENTS.PRIVACY_POLICY_CLICK);
        } else if (pageName === "tnc") {
            recordGAEvent(GA_EVENTS.TERMS_AND_CONDITIONS_CLICK);
        } else if (pageName === "partner") {
            recordGAEvent(GA_EVENTS.PARTNER_WITH_US_CLICK);
        } else if (pageName === "faq") {
            recordGAEvent(GA_EVENTS.FAQ_CLICK);
        }
        window.open(pageLink, "_blank");
    }

    const handleSocialsClick = (event: React.MouseEvent<htmlbuttonelement, MouseEvent=""> , socialName: string, socialLink: string) => {
        event.preventDefault();
        if (socialName === "instagram") {
            recordGAEvent(GA_EVENTS.INSTAGRAM_CLICK);
        } else if (socialName === "twitter") {
            recordGAEvent(GA_EVENTS.TWITTER_CLICK);
        } else if (socialName === "tiktok") {
            recordGAEvent(GA_EVENTS.TIKTOK_CLICK);
        } else if (socialName === "linkedin") {
            recordGAEvent(GA_EVENTS.LINKEDIN_CLICK);
        }
        window.open(socialLink, "_blank");
    }

    return (
        <div className="w-screen bg-[#0E0F0C] flex justify-center items-center">
            <div className="w-[90%] h-[273px] md:h-[338px] bg-[#FEC330] flex flex-col justify-center items-center rounded-xl">
                <div className="w-full h-1/3 flex justify-center items-center">
                    <muzifylogo className="w-[180px] md:w-[233.5px] h-[67.5px] md:h-[87.5px]"></muzifylogo>
                </div>
                <div className="h-[62px] flex flex-col justify-between items-center">
                    <div className="font-inter font-medium text-[14px] md:text-[20px] text-[#1F1300] flex justify-center items-center">
                        Stay tuned for your path to musical domination!
                    </div>
                    <div className="flex flex-row">
                        {
                            SOCIALS.map((social) => (
                                <button className="px-2" key="{social.name}" onClick="{(e)" ==""> handleSocialsClick(e, social.name, social.link)}
                                >
                                  {social.icon}
                                </button>
                            ))
                        }
                    </div>
                </div>
                <div className="w-[323px] md:w-[409px] mt-8 md:mt-4 flex flex-row justify-between items-center">
                    {
                        PAGES.map((page) => (
                            <button key="{page.name}" onClick="{(e)" ==""> handlePagesClick(e, page.name, page.link)}
                                className="font-inter text-[12px] md:text-[14px] leading-[16px] md:leading-[56px] underline underline-offset-4 text-[#1F1300] opacity-60"
                            >
                                {page.text}
                            </button>
                        ))
                    }
                </div>
            </div>
            
        </div>
    )
}

export default Footer;
</htmlbuttonelement,></htmlbuttonelement,>