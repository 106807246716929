import { useState } from "react"
import { ActiveConcert } from "../../constants/dataConstant"
import ButtonElement from "../../components/ButtonElement"

type ActiveConcertElementDesktopProps = {
    concert: ActiveConcert
}

const ActiveConcertElementDesktop = ({concert}: ActiveConcertElementDesktopProps) => {
    const [isHovered, setIsHovered] = useState(false)
    return (
        <div className="relative w-[262.98px] h-[305px] flex flex-col justify-center items-center bg-[#D9D9D9]" onMouseEnter="{()" ==""> setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
        >
            <img src="{concert.image}" className="w-[235.87px] h-[223.67px]">
            <div className="font-indie-flower text-[24px] leading-[80%] text-[#000000] mt-4">
                {concert.artist}
            </div>
            <div className="font-indie-flower text-[18px] leading-[100%] text-[#000000] mt-1">
                {`${concert.date}, ${concert.city}`}
            </div>

            {
                isHovered ? (
                    <div className="{`absolute" inset-0="" bg-black="" flex="" justify-center="" items-center="" ${isHovered="" ?="" 'bg-opacity-80'="" :="" 'bg-opacity-0'}`}="">
                        <buttonelement text="{&quot;Play" Now"}="" disabled="{false}" clickHandler="{()" ==""> {window.location.assign(`https://muzify.com/quiz/${concert.artist_id}`);}}/>
                    </buttonelement></div>
                ) : <></>
            }
        </div>
    )
}

export default ActiveConcertElementDesktop
