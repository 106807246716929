import { ActiveConcert } from "../../constants/dataConstant"
import ButtonElement from "../../components/ButtonElement"

type ActiveConcertElementMobileProps = {
    concert: ActiveConcert
}

const ActiveConcertElementMobile = ({concert}: ActiveConcertElementMobileProps) => {
    return (
        <div className="w-[160px] py-2 flex flex-col justify-center items-center bg-[#D9D9D9]">
            <img src="{concert.image}" className="w-[140px] h-[140px]">
            <div className="font-indie-flower text-[18px] leading-[100%] text-[#000000] mt-2">
                {concert.artist}
            </div>
            <div className="font-indie-flower text-[16px] leading-[100%] text-[#000000] mt-1">
                {`${concert.date}, ${concert.city}`}
            </div>
            <div className="flex justify-center items-center mt-2">
                <buttonelement text="Play Now" disabled="{false}" clickHandler="{()" ==""> {window.location.assign(`https://muzify.com/quiz/${concert.artist_id}`);}} />
            </buttonelement></div>
        </div>
    )
}

export default ActiveConcertElementMobile
