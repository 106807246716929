import { useState } from "react";
import Testimonial1 from "../../assets/images/Testimonial1.png";
import Testimonial2 from "../../assets/images/Testimonial2.png";
import Testimonial3 from "../../assets/images/Testimonial3.png";
import Testimonial4 from "../../assets/images/Testimonial4.png";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
const TESTIMONIALS = [Testimonial1, Testimonial2, Testimonial3, Testimonial4];

const Testimonials = () => {

    const [currentTestimonial, setCurrentTestimonial] = useState(0);

    const prevClick = () => {
        setCurrentTestimonial((prev) => prev === 0 ? 3 : prev - 1);
    }

    const nextClick = () => {
        setCurrentTestimonial((prev) => prev === 3 ? 0 : prev + 1);
    }

    return (
        <div className="mb-12 flex flex-col justify-center items-center">
            <div className="flex justify-center items-center font-firaSans font-bold text-transparent bg-clip-text text-[30px] md:text-[162px] text-center bg-gradient-to-b from-[#FEC330] to-[#0E0F0C]">
                FANDOM FLEX
            </div>
            <div className="hidden md:block">
                <div className="flex flex-row px-4 justify-between gap-4 items-center">
                    <div className="flex flex-col justify-center items-center">
                        <img src="{Testimonial1}/">
                        <div className="h-[50px]"></div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <div className="h-[50px]"></div>
                        <img src="{Testimonial2}/">
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <img src="{Testimonial3}/">
                        <div className="h-[50px]"></div>
                    </div>
                    <div className="flex flex-col justify-center items-center">
                        <div className="h-[50px]"></div>
                        <img src="{Testimonial4}/">
                    </div>
                </div>
            </div>
            <div className="p-4 flex flex-row justify-center items-center gap-4 md:hidden">
                {/* {
                    [Testimonial1, Testimonial2, Testimonial3, Testimonial4].map((testimonial, index) => (
                        <img key="{`image-${index}`}" src="{testimonial}" alt="{`image-${index}`}" style="{{" opacity:="" "0.9"="" }}="" className="w-[230.76] h-[300px] animate-slide-left rounded-3xl object-cover">
                    ))
                } */}
                <div className="flex justify-center items-center opacity-20" onClick="{prevClick}">
                    <fachevroncircleleft color="white" fontSize="{&quot;2em&quot;}"></fachevroncircleleft>
                </div>
                <img src="{TESTIMONIALS[currentTestimonial]}" style="{{" opacity:="" "0.9"="" }}="" className="w-[230.76] h-[300px] object-cover">
                <div className="flex justify-center items-center opacity-20" onClick="{nextClick}">
                    <fachevroncircleright color="white" fontSize="{&quot;2em&quot;}"></fachevroncircleright>
                </div>
            </div>
        </div>
    )
}

export default Testimonials;
