import "../../index.css";
import Header from "./Header";
import Footer from "./Footer";
import Hero from "./Hero";
import AppHighlights from "./AppHighlights";
import AppFeatures from "./AppFeatures";
import Testimonials from "./Testimonials";
import Sponsors from "./Sponsors";

const HomePage = () => {
    return (
        <div className="w-screen flex flex-col justify-center items-center bg-[#0E0F0C] overflow-x-clip">
            {/* Header */}
            <header></header>

            {/* Quiz and side carousels */}
            <hero></hero>

            {/* App Highlights */}
            <apphighlights></apphighlights>

            {/* App features */}
            <appfeatures></appfeatures>

            {/* Testimonials */}
            <testimonials></testimonials>

            {/* Sponsors */}
            <sponsors></sponsors>

            {/* Footer */}
            <footer></footer>

            <div className="w-screen h-[50px]"></div>
        </div>
    )
}

export default HomePage;
