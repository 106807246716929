import axios, { AxiosResponse } from 'axios';

let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null;

const axiosApiInstance = axios.create({
  baseURL: process.env.REACT_APP_ASSET_API,
  headers: {
    Authorization: `Bearer ${user?.stsTokenManager?.accessToken}`,
    'Content-Type': 'application/json',
    accept: 'application/json',
  },
});

const handleResponse = (response: AxiosResponse<any>) => {
  if (response.status === 401 || response.status === 403) {
    localStorage.removeItem('user');
    window.location = '/' as any;
  }
  return response;
};

axiosApiInstance.interceptors.request.use(async (req) => {
  const currUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : null;
  req.headers.Authorization = `Bearer ${currUser?.stsTokenManager?.accessToken}`;
  return req;
});

axiosApiInstance.interceptors.response.use(
  (response) => {
    return handleResponse(response);
  },
  (error) => {
    handleResponse(error.response);
    throw error;
  },
);

export default axiosApiInstance;
</any>