import ConcertPageLogo from "../../assets/images/concert-page-logo.png"
import ActiveConcerts from "./ActiveConcerts"
import HowToEnter from "./HowToEnter"
import HowToEnterDesktop from "./HowToEnterDesktop"
import FandomFlex from "./FandomFlex"

const ConcertsPage = () => {

    return (
        <div className="w-screen bg-[#0E0F0C] overflow-y-scroll flex flex-col justify-center items-center">
            {/* Logo */}
            <div className="mt-4">
                <a href="https://ja.muzify.com">
                    <img src="{ConcertPageLogo}" alt="Muzify logo" className="w-[146px] md:w-[158.16px] h-[54.71px] md:h-[59.27px]">
                </a>
            </div>
            {/* Header Text */}
            <div className="mt-4 w-full flex justify-center items-center">
                <div className="w-[90%] flex flex-col justify-center items-center gap-2">
                    <div className="w-full flex justify-center items-center">
                        <span className="font-inter text-[#FFFFFF] text-center text-[28px] md:text-[56px] leading-[110%]">{`Win Free Concert Tickets!`}</span>
                    </div>
                    <div className="w-full flex justify-center items-center">
                        <span className="font-inter text-[#FFFFFF] text-center text-[14px] md:text-[24px] leading-[110%]">{`Beat the quiz. Grab the tickets. Live your main character era!`}</span>
                    </div>
                </div>
            </div>
            {/* Concert Gallery */}
            <activeconcerts></activeconcerts>

            <fandomflex></fandomflex>

            <div className="w-full flex md:hidden mt-8 flex-col justify-center items-center gap-8">
                <howtoenter></howtoenter>
            </div>

            <div className="w-full hidden md:flex mt-8 flex-col justify-center items-center gap-16">
                <howtoenterdesktop></howtoenterdesktop>
            </div>

            <div className="h-[32px] md:h-[64px]"></div>
        </div>
    )
}

export default ConcertsPage
