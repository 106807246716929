import { ACTIVE_CONCERTS } from "../../constants/dataConstant"
import ActiveConcertElementDesktop from "./ActiveConcertElementDesktop"
import ActiveConcertElementMobile from "./ActiveConcertElementMobile"

const ActiveConcerts = () => {
    return (
        <div className="w-[90%] mt-4 py-4 md:py-8 flex flex-col justify-center items-center bg-[#D9D9D9] md:bg-[#0E0F0C] bg-opacity-10 md:bg-opacity-100 rounded-md">
            {/* <div className="flex justify-center items-center font-inter font-extralight italic text-[24px] md:text-[36px] leading-[110%] text-[#FFFFFF] text-opacity-60">
                {`Active Concerts`}
            </div>
            <div className="mt-4 w-[298px] md:w-[400px] font-inter font-light text-[14px] md:text-[18px] leading-[110%] text-[#FFFFFF] text-opacity-60 text-center">
                {`Beat the quiz. Grab the tickets. Live your main character era!`}
            </div> */}
            <div className="hidden md:flex mt-4 flex-row flex-wrap justify-center items-center gap-4">
                {
                    ACTIVE_CONCERTS.map((activeConcert, index) => {
                        return <activeconcertelementdesktop key="{`active_concert_${index+1}`}" concert="{activeConcert}"></activeconcertelementdesktop>
                    })
                }
            </div>
            <div className="flex md:hidden mt-4 flex-row flex-wrap justify-center items-center gap-2">
                {
                    ACTIVE_CONCERTS.map((activeConcert, index) => {
                        return <activeconcertelementmobile key="{`active_concert_${index+1}`}" concert="{activeConcert}"></activeconcertelementmobile>
                    })
                }
            </div>
        </div>
    )
}

export default ActiveConcerts
