import AntlerLogo from "../../assets/images/partner-antler-logo.png"
import DecentralizedLogo from "../../assets/images/partner-decentralised-co-logo.png"
import CoinswitchLogo from "../../assets/images/partner-coinswitch-ventures-logo.png"
import SequoiaLogo from "../../assets/images/partner-sequoia.png"

const Sponsors = () => {

    return (
        <div className="w-screen bg-[#0E0F0C] flex justify-center items-center mt-8 mb-8">
            <div className="w-[90%] py-4 bg-[#5C5C5C] bg-opacity-30 flex flex-col justify-center items-center rounded-xl">
                <div className="font-inter font-bold text-[#FFFFFF] leading-[110%] text-[16px] md:text-[24px]">
                    {`BACKED BY`}
                </div>
                <div className="flex flex-wrap justify-center items-center gap-x-8 md:gap-x-16 gap-y-4 mt-4">
                    <img src="{AntlerLogo}" className="w-[121px] md:w-[165px] h-auto">
                    <img src="{DecentralizedLogo}" className="w-[156px] md:w-[250px] h-auto">
                    <img src="{CoinswitchLogo}" className="w-[148px] md:w-[189px] h-auto">
                    <img src="{SequoiaLogo}" className="w-[135.73px] md:w-[194.65px] h-auto">
                </div>
            </div>
        </div>
    )
}

export default Sponsors
