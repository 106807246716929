const Disclaimer = () => {
    return (
        <div className="font-inter leading-[110%] font-extralight italic text-[18px] text-center">
            <span className="text-[#FFFFFF] text-opacity-60">{`*Does `}</span>
            <span className="text-[#FFFFFF] text-opacity-100">{`NOT `}</span>
            <span className="text-[#FFFFFF] text-opacity-60">{`include travel & accommodation. Winners are expected to capture & share event snippets as part of this contest on socials.`}</span>
        </div>
    )
}

const HowToEnterDesktop = () => {
    return (
        <div className="w-[90%] h-[544px] py-4 px-5 flex flex-col justify-evenly items-center bg-[#D9D9D9] bg-opacity-10 rounded-md overflow-y-auto">
            <div className="flex justify-center items-center font-inter font-extralight italic text-[24px] md:text-[36px] leading-[110%] text-[#FFFFFF] text-opacity-60">
                {`How To Enter`}
            </div>
            <div className="mt-4 w-full flex flex-col justify-start items-center font-inter leading-[130%] text-[#FFFFFF] text-[18px] gap-3">
                <div className="w-full flex flex-row justify-center items-start">
                    <div className="w-[10%] flex justify-center items-center">{`1. `}</div>
                    <div className="w-[90%]">{`Click 'Play quiz!' and enjoy playing Muzify for your favourite artists`}</div>
                </div>
                <div className="w-full flex flex-row justify-center items-start">
                    <div className="w-[10%] flex justify-center items-center">{`2. `}</div>
                    <div className="w-[90%]">{`Sign up and save your fandom scorecards on the fan leaderboard. The more artists you play, the higher your chances are to win`}</div>
                </div>
                <div className="w-full flex flex-row justify-center items-start">
                    <div className="w-[10%] flex justify-center items-center">{`3. `}</div>
                    <div className="w-[90%]">{`Fill up your name and city in the concert deets Popup after sign up`}</div>
                </div>
                <div className="w-full flex flex-row justify-center items-start">
                    <div className="w-[10%] flex justify-center items-center">{`4. `}</div>
                    <div className="w-[90%]">{`Next, record your quiz and share it on Instagram or YouTube`}</div>
                </div>
                <div className="w-full flex flex-row justify-center items-start">
                    <div className="w-[10%] flex justify-center items-center">{`5. `}</div>
                    <div className="w-[90%]">{`Follow & Share your fandom scorecards on X & Instagram using the hashtag #MuzifyConcert. Share your fandom scorecards with 5 friends to challenge them to play the quiz and beat your score`}</div>
                </div>
                <div className="w-full flex flex-row justify-center items-start">
                    <div className="w-[10%] flex justify-center items-center">{`6. `}</div>
                    <div className="w-[90%]">{`Winners will be updated via their email`}</div>
                </div>
            </div>
            <div className="mt-4 px-2 md:px-4 w-full">
                <disclaimer></disclaimer>
            </div>
        </div>
    )
}

export default HowToEnterDesktop
