import Footer from './components/Footer';
import Header from './components/Header';
import useClevertap from './hooks/useClevertap';
import HomePage from './screens/Homepage';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import React, { useEffect } from 'react';
import './index.css';
import { Route, Routes } from "react-router-dom";
import PrivacyPolicy from './screens/privacyPolicy';
import TermsAndConditions from './screens/termsAndConditions';
import ReactGA from 'react-ga4';
import useGA from './hooks/useGA';
import { GA_EVENTS } from "./constants/dataConstant";
import ConcertsPage from './screens/concerts';

function App() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  if (process.env.REACT_APP_DATABASE_URL) {
    firebaseConfig.databaseURL = process.env.REACT_APP_DATABASE_URL;
  }

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  // const auth = getAuth(app);
  const db = getFirestore(app);
  // clevertap event push
  const { recordGAEvent } = useGA();

  useEffect(() => {
    console.log("INITIALIZE G-TAG");
    ReactGA.initialize("G-SGRZPVFCQ1");
    ReactGA.send({
      hitType: "pageview",
      page: window?.location?.pathname ?? "/",
    });
    recordGAEvent(GA_EVENTS.MUZIFY_LANDING_PAGE_VIEW);
  }, []);

  return (
    <routes>
    <route index="" path="/" element="{<HomePage"></route>} />
    <route index="" path="/privacy-policy" element="{<PrivacyPolicy/">} />
    <route index="" path="/terms-and-conditions" element="{<TermsAndConditions/">} />
    <route index="" path="/concerts" element="{<ConcertsPage"></route>} />
  </route></route></routes>
  );
}

export default App;
